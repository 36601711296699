@use '../styles';
@import 'node_modules/include-media/dist/_include-media.scss';

.__about-me {
  display: flex;
  height: 100%;
  min-height: -webkit-fill-available;
  min-height: -moz-available;
  min-height: stretch;
  
  background-color: styles.$orange;
  padding: 20px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  p {
    font-size: clamp(24px, 3vw, 44px);
    line-height: clamp(34px, 3vw, 54px);
    margin: 20px 0;
    font-weight: lighter;

    &.bottom {
      text-align: right;
    }
  }
  
  h1 {
    font-weight: 700;
    font-size: clamp(32px, 9vw, 200px);
    text-transform: uppercase;
    color: white;
    margin: 20px 0;
    text-align: center;

    text-shadow: 0px 4px 4px rgba(styles.$red, 0.3);

    @include media("<=768px") {
      font-size: clamp(24px, 14vw, 120px);
      line-height: clamp(24px, 20vw, 132px);
      
      strong {
        font-size: clamp(24px, 23vw, 180px);
      }
    }
  }

  .__link-button {
    align-self: end;
  }
}