@use '../styles';
@import 'node_modules/include-media/dist/_include-media.scss';

.__link-button {
  // padding: clamp(8px, 2vw, 16px); 16px 60px;
  padding: clamp(10px, 5vw, 16px) clamp(40px, 5vw, 60px);
  color: white;
  background-color: styles.$black;
  text-decoration: none;
  border-radius: 8px;
  font-size: clamp(24px, 2vw, 30px);
  transition: 0.3s transform, 0.3s opacity;

  @include media("<=440px") {
    margin: 0 auto;
    width: 100%;
    text-align: center;
  }

  &:hover {
    transform: translateY(-3px);
    opacity: 0.9;
  }
}
