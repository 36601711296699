@use '../styles';

.__nav-header {
  display: flex;
  gap: 32px;
  justify-content: flex-end;
  width: 100%;

  a {
    text-decoration: none;
    color: styles.$black;
    padding-bottom: 2px;
    font-weight: lighter;
    border-bottom: 2px solid transparent;

    &.active, &:hover {
      border-bottom-color: styles.$red;
      transition: border-bottom-color 0.3s;
    }
  }
}
