@use '../styles';
@import 'node_modules/include-media/dist/_include-media.scss';

#support-me {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background-color: styles.$black;
  color: white;
  padding: clamp(10px, 1vw, 20px);

  h2 {
    font-size: clamp(36px, 5vw, 80px);
    text-transform: uppercase;
    margin: clamp(30px, 5vw, 50px) clamp(0px, 5vw, 40px);

    @include media("<=720px") {
      text-align: center;
    }  
  }

  .content {
    max-width: 800px;
    width: 50vw;
    margin: 0 auto;
    font-size: clamp(18px, 2vw, 22px);
    line-height: clamp(36px, 3vw, 44px);
    font-weight: lighter;

    @include media("<=720px") {
      width: 100%;
    }

    p {
      margin: clamp(24px, 2vw, 30px) 0;
    }

    p.donate-button {
      text-align: center;

      .__link-button {
        display: inline-block;
        border: 2px solid styles.$orange;
        color: styles.$orange;
        border-radius: 16px;
        font-weight: bold;
        margin-top: 32px;
        font-size: clamp(20px, 1.6vw, 24px);
        padding: clamp(10px, 4vw, 12px) clamp(32px, 8vw, 100px);
      }
    }

    p.lnurl-button {
      text-align: center;

      .__link-button {
        display: inline-block;
        border: 2px solid styles.$orange;
        color: styles.$orange;
        border-radius: 16px;
        font-weight: bold;
        margin-top: 20px;
        font-size: clamp(16px, 1.4vw, 20px);
        padding: clamp(10px, 4vw, 12px) clamp(16px, 4vw, 50px);
      }
    }
  }
}
